export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Program",
    model: null,
    options: [
      { value: 0, label: "ALL"},
      { value: 2, label: "BOOST CREDIT"},
      { value: 3, label: "CREDIT EXPERTS"},
      { value: 4, label: "DEBT SOLUTION"},
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [
      { value: 0, label: 'ALL' },
      { value: 1, label: 'REGULAR' },
      { value: 2, label: 'CERTIFIED' },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Specialist",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name_status",
    cols: 12,
  }
];
