<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0  border-bottom-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="Process"
      title-class="h2 text-white"
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalHeader
          :dataClient="dataClient"
          :title="'PROCESS'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-row>
          <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
            <h6 class="text-white text-center">OBSERVATION</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-observer ref="refFormObserver">
              <validation-provider
                name="Creditor Name"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="observation"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </validation-provider>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="onSubmit">
          {{ inAdministration ? "Process" : "Save" }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalHeader from "./ModalHeader.vue";
import LettersService from "../../service/letters.service";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ModalHeader,
    ButtonSave,
    ButtonCancel,
    ValidationObserver,
  },
  props: {
    dataClient: {
      type: Object,
    },
    type: {
      type: Number,
    },
  },
  data() {
    return {
      modalCenter: true,
      observation: "",
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module
    },
    inAdministration() {
      return this.dataClient?.state === 2
    },
  },
  methods: {
    onSubmit() {
      if (this.inAdministration) {
        this.processLetter()
      } else {
        this.onSave()
      }
    },
    async onSave() {
      if (await this.$refs.refFormObserver.validate()) {
        let confirm = await this.showConfirmSwal()
        if (confirm.isConfirmed) {
          this.addPreloader()
          try {
            await LettersService.insertProcessLetters({
              id: this.dataClient.id,
              user: this.currentUser.user_id,
              type: this.type,
              obs: this.observation,
              ittra: this.dataClient.sumtr ? this.dataClient.sumtr : 0,
              itexp: this.dataClient.sumex ? this.dataClient.sumex : 0,
              itequ: this.dataClient.sumeq ? this.dataClient.sumeq : 0,
              module: this.moduleId,
            })
            this.$emit("updateTable")
            this.hideModal()
            this.removePreloader()
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "The letter has been update."
            )
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    async processLetter() {
      try {
        const { isConfirmed } = await this.showConfirmSwal()
        if (!isConfirmed) return
        this.addPreloader()
        await LettersService.processRoundLetters({
          id: this.dataClient.id,
          user: this.currentUser.user_id,
          type: this.type,
          obs: this.observation,
          ittra: this.dataClient.sumtr ? this.dataClient.sumtr : 0,
          itexp: this.dataClient.sumex ? this.dataClient.sumex : 0,
          itequ: this.dataClient.sumeq ? this.dataClient.sumeq : 0,
        })
        this.$emit("updateTable")
        this.hideModal()
        this.removePreloader()
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "The letter has been process."
        )
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false
      this.$emit("hideModal")
    },
  },
  mounted() {},
}
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>
