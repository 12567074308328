<template>
  <div>
    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :filter="filteredFilters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refLettersTable'].refresh()"
      >
        <b-table
          slot="table"
          ref="refLettersTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          sort-icon-left
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="filteredFields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_status)="data">
            <div class="pt-1">
              <status-account
                :account="data.item"
                :text="false"
              ></status-account>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div style="padding-top: 5px">
              <router-link
                class
                :to="{
                  name:
                    $route.matched[0].meta.module == 25
                      ? 'specialist-digital-dashboard'
                      : $route.matched[0].meta.module == 11
                      ? 'specialists-client-dashboard'
                      : null,
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
              <div class="m-0">{{ data.item.mobile }}</div>
              <div class="m-0">{{ data.item.account }}</div>
              <badge-risky-clients
                :id-account="data.item.client_account_id"
                :risk-level="data.item.risk_level"
                :risk-description="data.item.risk_description"
                :risk-type="data.item.risk_type"
              />
            </div>
          </template>
          <template #cell(type_send)="data">
            <div class="text-center">
              <b-badge
                :class="data.item.t_send == 1 ? 'bg-success' : 'bg-warning'"
                style="padding: 5px; border-radius: 10px"
                >{{ data.item.type_send }}</b-badge
              >
            </div>
          </template>
          <template #cell(title)="data">
            <div class="text-center cursor-pointer text-primary">
              <div @click="openModalAddRound(data.item)">
                {{ data.item.title.split(" ")[0] }}
              </div>
            </div>
          </template>
          <template #cell(sumtr)="data">
            <div class="text-center">
              {{ data.item.sumtr }}
            </div>
          </template>
          <template #cell(sumex)="data">
            <div class="text-center">
              {{ data.item.sumex }}
            </div>
          </template>
          <template #cell(sumeq)="data">
            <div class="text-center">
              {{ data.item.sumeq }}
            </div>
          </template>
          <template #cell(date)="data">
            <div class="pt-1">
              {{ data.item.date }}
            </div>
          </template>
          <template #cell(spec_name)="data">
            <div class="text-center">
              {{ data.item.spec_name }}
            </div>
          </template>
          <template #cell(cr_date)="data">
            <div class="text-center">
              <div>
                {{ data.item.created_by }}
              </div>
              <div>
                {{ data.item.cr_date }}
              </div>
            </div>
          </template>
          <template #cell(program)="data">
            <div class="text-center">
              {{ data.item.program }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${paintLetterStatus(data.item.status)}`"
                class="text-capitalize w-100"
                >{{ data.item.status }}</b-badge
              >
            </div>
          </template>
          <template #cell(dispute)="data">
            <div class="text-center">
              {{ data.item.sumtr + data.item.sumex + data.item.sumeq }}
            </div>
          </template>
          <template #cell(tracking)="data">
            <div class="text-center">
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                icon="ListIcon"
                @click="openModalTracking(data.item)"
                size="20"
              />
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-between">
              <feather-icon
                v-if="isInPendingLetterTab"
                class="text-info cursor-pointer"
                icon="SettingsIcon"
                @click="openModalProcess(data.item, 2)"
                size="20"
              />
              <feather-icon
                class="text-primary cursor-pointer"
                icon="EditIcon"
                v-if="isModule == 11 || isModule == 25"
                @click="
                  openModalEditRoundLetter(
                    data.item.id,
                    data.item.client_account_id,
                    data.item.account,
                    data.item.client_name
                  )
                "
                size="20"
              />
              <feather-icon
                v-if="isModule == 11 || isModule == 25"
                @click="deleteRoundLetter(data.item.id)"
                class="text-danger cursor-pointer"
                icon="TrashIcon"
                size="20"
              />
              <div
                v-if="!isInCompletedLetterTab && !isInPendingLetterTab"
                class="cursor-pointer text-primary text-center pl-1"
                @click="resendLetter(data.item.id, 2)"
              >
                <p class="font-weight-bold">SEND</p>
              </div>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <modal-process
      v-if="modalProcessState"
      :dataClient="dataProcess"
      :type="type"
      @updateTable="updateTable"
      @hideModal="modalProcessState = false"
    />
    <modal-tracking
      v-if="modalTrackingState"
      :dataClient="dataClient"
      @hideModal="modalTrackingState = false"
    />
    <modal-edit-round-letter
      v-if="modalEditRoundLetterState"
      :idround="ncrId"
      :idaccount="clientAccountId"
      :account="accountId"
      :nameClient="nameClient"
      @updateTable="updateTable"
      @hideModal="modalEditRoundLetterState = false"
    />
    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import LettersService from "../services/letters-round.service";
import ClientOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import DataFields from "../data/fields.data";
import DataFilters from "../data/filters.data";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ModalTracking from "@/views/commons/components/letters/components/modal/ModalTracking.vue";
import ModalProcess from "@/views/commons/components/letters/components/modal/ModalProcess.vue";
import ModalEditRoundLetter from "@/views/specialist-digital/views/letters-round/components/modal/ModalEditRoundLetter.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    FilterSlot,
    ModalTracking,
    ModalProcess,
    ModalEditRoundLetter,
    AddRoundModal,
    BadgeRiskyClients,
    StatusAccount,
  },
  async created() {
    await this.getSpecialists();
    if (this.isInCompletedLetterTab) await this.getLettersStatus();
    this.fields[14].visible =
      this.isInReturnedLetterTab || this.isInPendingLetterTab;
    this.filters[5].showLabel = this.isInCompletedLetterTab;
  },
  props: {
    statusLetterType: {
      type: String,
    },
  },
  data() {
    return {
      fields: DataFields,
      filters: DataFilters,
      sortBy: "created_at",
      sortDesc: true,
      isBusy: false,
      itemClient: {
        id: null,
        idAccount: null,
        account: null,
        clientName: null,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      status: null,
      toPage: 0,
      totalRows: 0,
      type: null,
      statusColor: "",
      dataClient: [],
      dataProcess: [],
      dataEdit: [],
      modalTrackingState: false,
      modalProcessState: false,
      modalEditRoundLetterState: false,
      modalAddRoundLetterState: false,
      ncrId: null,
      clientAccountId: null,
      accountId: null,
      nameClient: null,
    };
  },
  methods: {
    async getLettersStatus() {
      try {
        let result = await LettersService.getLettersStatus();
        if (result) {
          result.unshift({ id: 0, name_status: "All" });
        }
        this.filters[5].options = result;
      } catch (error) {
        console.error("Error trying to get letters status", error);
      }
    },
    async getSpecialists() {
      try {
        const data = await LettersService.getAdvisorsSpecialists();
        this.filters[4].options = data;
      } catch (error) {
        console.error("Error trying to get specialists", error);
      }
    },
    async deleteRoundLetter(id) {
      try {
        const result = await this.showConfirmSwal();
        if (!result.isConfirmed) return;
        const params = { id: id, user_id: this.currentUser.user_id };
        const response = await ClientOptions.deleteRoundLetter(params);
        if (response.status === 200) {
          this.showSuccessSwal();
          this.updateTable();
        }
      } catch (error) {
        console.error("Error trying to delete a round letter", error);
      }
    },
    async resendLetter(id, type) {
      let confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;
      try {
        await LettersService.insertProcessLetters({
          id: id,
          user: this.currentUser.user_id,
          type: type,
          obs: "",
        });
        this.UPDATE_TAB_ROUNDLETTERS_COUNTER({
          pending: this.returnedLetters - 1,
        });
        this.UPDATE_SIDEBAR_ROUNDLETTER_COUNTER({
          routeName: "specialistdigital-letters",
          tag: this.returnedLetters < 2 ? "" : this.returnedLetters - 1,
        });
        this.showSuccessSwal();
        this.updateTable();
      } catch (error) {
        console.error("Error trying to resend a letter", error);
      }
    },
    async myProvider(ctx) {
      try {
        let result = [];
        let params = {
          name_text: this.filterPrincipal.model,
          f_specialist: this.filters[4].model,
          order: ctx.sortDesc == 1 ? "desc" : "asc",
          orderby: 8,
          program: this.filters[2].model,
          roleid: this.currentUser.role_id,
          from: this.filters[0].model,
          to: this.filters[1].model,
          userid: this.currentUser.user_id,
          status: this.isLetterReturned,
          typesend: this.filters[3].model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          statusletters: this.filters[5].model,
          typeClient: this.typeClient,
        };
        if (this.isInCompletedLetterTab) {
          result = await LettersService.getCompletedRoundLetters(params);
        } else {
          result = await LettersService.getPendingRoundLetters(params);
        }
        this.startPage = result.from;
        this.toPage = result.to;
        this.totalRows = result.total;
        this.paginate.currentPage = result.current_page;
        this.paginate.perPage = result.per_page;
        return result.data || [];
      } catch (error) {
        console.error("Something went wrong with the provider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    paintLetterStatus(status) {
      if (
        status === "IN ADVISOR" ||
        status === "RETURN BY ADVISOR" ||
        status === "IN SPECIALIST (RETURN)"
      )
        return "warning";
      if (status === "SENT") return "success";
      if (status === "IN CORRESPONDENCE") return "primary";
      return "primary";
    },
    openModalTracking(data) {
      this.dataClient = data;
      this.modalTrackingState = true;
    },
    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idAccount: data.client_account_id,
        account: data.account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        isDirect: { id: data.id, title: data.title, format: data.format },
      };
      this.modalAddRoundLetterState = true;
    },
    openModalProcess(data, type) {
      this.dataProcess = data;
      this.type = type;
      this.modalProcessState = true;
    },
    openModalEditRoundLetter(ncr_id, client_account_id, account, name_client) {
      this.ncrId = ncr_id;
      this.clientAccountId = client_account_id;
      this.accountId = account;
      this.nameClient = name_client;
      this.modalEditRoundLetterState = true;
    },
    updateTable() {
      this.$refs.refLettersTable.refresh();
    },
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
      UPDATE_TAB_ROUNDLETTERS_COUNTER:
        "SpecialistsDigitalRoundLettersStore/A_SET_COUNTER_RETURNED_ROUND_LETTERS",
    }),
    ...mapMutations({
      UPDATE_SIDEBAR_ROUNDLETTER_COUNTER:
        "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
      returnedLetters:
        "SpecialistsDigitalRoundLettersStore/G_COUNTER_RETURNED_ROUND_LETTERS",
    }),
    isLetterReturned() {
      if (this.$route.matched[0].meta.module == 25) {
        if (this.statusLetterType === "returned") return 4;
        return 1;
      } else if (this.$route.matched[0].meta.module == 11) {
        return null;
      }
    },
    isInCompletedLetterTab() {
      return this.$route.meta.typeTab === "completed";
    },
    isInPendingLetterTab() {
      return this.$route.meta.typeTab === "pending";
    },
    isInReturnedLetterTab() {
      return this.isLetterReturned === 4;
    },
    filteredFields() {
      return this.fields.filter((field) => field.visible);
    },
    filteredFilters() {
      return this.filters.filter((filter) => filter.showLabel);
    },
    validateModal() {
      return this.isInPendingLetterTab ? 0 : 3;
    },
    typeClient() {
      if (this.$route.matched[0].meta.module == 25) {
        return 2;
      } else if (this.$route.matched[0].meta.module == 11) {
        return null;
      }
    },

    isModule() {
      if (this.$route.matched[0].meta.module == 25) {
        return 25;
      } else if (this.$route.matched[0].meta.module == 11) {
        return 11;
      }
    },
  },
  watch: {
    updateLetterTable(newState) {
      if (newState) {
        this.$refs.refLettersTable.refresh();
        this.A_UPDATE_TABLE(false);
      }
    },
  },
};
</script>
<style scoped>
.widthClients {
  padding: 4px;
  width: 30px;
  border-radius: 50px;
}
.yellow {
  color: #ffc107;
}
</style>
