export default [
  {
    key: "client_status",
    label: "",
    visible: true,
  },
  {
    key: "client_name",
    label: "Client",
    visible: true,
  },
  {
    key: "type_send",
    label: "Type",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "title",
    label: "ROUND",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "sumtr",
    label: "TUR",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "sumex",
    label: "EXP",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "sumeq",
    label: "EQR",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "date",
    label: "Date of CR",
    visible: true,
  },
  {
    key: "program",
    label: "Program",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "spec_name",
    label: "Specialist",
    tdClass: "pt-1",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "cr_date",
    label: "Created by",
    sortable: true,
    thClass: "text-center",
    visible: true,
  },
  {
    key: "status",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "dispute",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "actions",
    visible: true,
  }
];